import { GeosearchResponse } from "~/bff/transport/Geosearch";
import {
  GeosearchWithInventoryResponse,
  GeosearchWithInventoryVariables,
} from "~/bff/transport/GeosearchWithInventory";
import { Store } from "~/bff/types/Store";
import { StoresResponse } from "~/bff/types/StoresResponse";
import {
  GEOLOCATION_ERROR_GETTING_YOUR_LOCATION,
  GEOLOCATION_ERROR_IS_NOT_AVAILABLE,
  IS_GEOLOCATION_AVAILABLE,
} from "~/components/store-locator-page/components/search-field/constants";
import { DEFAULT_STORES_RADIUS } from "~/components/store-locator-page/constants";
import { StoreFilters } from "~/constants/data-layer";
import { LOCALES } from "~/constants/i18n";
import { getApolloClient } from "~/graphql/client";
import { GET_STORES_WITH_INVENTORY_FOR_SEARCH } from "~/graphql/queries/getStoresAvailability";
import { GET_STORES_FOR_SEARCH_FILTERS } from "~/graphql/queries/getStoresForSearch";
import { Nullable } from "~/types/general.types";
import { Logger } from "~/utils/logger";

interface FindByAddressResponse extends StoresResponse {
  formatted_address?: string;
}

interface MatchedSubstrings {
  length: number;
  offset: number;
}

interface Prediction {
  description: string;
  matched_substrings: MatchedSubstrings[];
  place_id: string;
  types: string[];
}

export const getPrediction = (
  predictions: Partial<Prediction[]>,
  address: string,
): Prediction | null => {
  const prediction = predictions?.find(
    (prediction) =>
      prediction?.description?.toLocaleLowerCase() === address?.toLocaleLowerCase(),
  );

  const result = prediction ? prediction : predictions[0];

  return result ?? null;
};

export const findByAddress = async (
  locale: LOCALES,
  region: string,
  address: string,
  sku?: string,
): Promise<Nullable<FindByAddressResponse>> => {
  return new Promise<Nullable<FindByAddressResponse>>((resolve, reject) => {
    const trimmed = address?.trim();
    if (trimmed === "") {
      resolve({ stores: [] });
      return;
    }
    const autocomplete = new google.maps.places.AutocompleteService();
    autocomplete.getPlacePredictions({ input: address }, (predictions, status) => {
      if (
        status !== google.maps.places.PlacesServiceStatus.OK ||
        !predictions?.length
      ) {
        resolve({ stores: [] });
        return;
      }

      const prediction = getPrediction(predictions, address);

      new google.maps.Geocoder().geocode(
        {
          placeId: prediction?.place_id,
          region: region,
        },
        async (
          results: google.maps.GeocoderResult[] | null,
          status: google.maps.GeocoderStatus,
        ) => {
          if (
            status !== google.maps.GeocoderStatus.OK &&
            status !== google.maps.GeocoderStatus.ZERO_RESULTS
          ) {
            reject(new Error("Something went wrong"));
            return;
          }
          if (!results || results.length === 0) {
            resolve({ stores: [] });
            return;
          }
          const result = results[0];
          const {
            geometry: {
              location: { lat, lng },
            },
            formatted_address,
          } = result;
          const latitude = lat();
          const longitude = lng();
          try {
            const { data } = await getApolloClient({ locale }).query<
              GeosearchResponse & GeosearchWithInventoryResponse,
              GeosearchWithInventoryVariables
            >({
              query: sku
                ? GET_STORES_WITH_INVENTORY_FOR_SEARCH
                : GET_STORES_FOR_SEARCH_FILTERS,
              variables: {
                sku: sku as string,
                locale,
                latitude: latitude,
                longitude: longitude,
                radius: DEFAULT_STORES_RADIUS,
              },
            });
            const response = sku ? data?.geosearchWithInventory : data?.geosearch;
            if (!response) {
              resolve({ stores: [] });
              return;
            }
            resolve({
              ...response,
              stores: response.stores || [],
              formatted_address,
            });
          } catch (error) {
            reject(error);
          }
        },
      );
    });
  });
};

export const findByGeolocation = async (
  locale: LOCALES,
  _region?: string,
  sku?: string,
): Promise<Nullable<Store[]>> => {
  return new Promise<Nullable<Store[]>>((resolve, reject) => {
    if (!IS_GEOLOCATION_AVAILABLE) {
      reject(new Error(GEOLOCATION_ERROR_IS_NOT_AVAILABLE));
      return;
    }
    navigator?.geolocation?.getCurrentPosition(
      async (position: GeolocationPosition) => {
        const {
          coords: { latitude, longitude },
        } = position;
        try {
          const { data } = await getApolloClient({ locale }).query<
            GeosearchResponse & GeosearchWithInventoryResponse,
            GeosearchWithInventoryVariables
          >({
            query: sku
              ? GET_STORES_WITH_INVENTORY_FOR_SEARCH
              : GET_STORES_FOR_SEARCH_FILTERS,
            variables: {
              sku: sku as string,
              locale,
              latitude,
              longitude,
              radius: DEFAULT_STORES_RADIUS,
            },
          });
          const stores = sku
            ? data?.geosearchWithInventory?.stores
            : data?.geosearch?.stores;
          const validStores = (stores || []).filter(
            (store): store is Store => store !== null,
          );
          resolve(validStores);
        } catch (error) {
          Logger.getLogger().error(String(error));
          reject(error);
        }
      },
      (error: GeolocationPositionError) => {
        Logger.getLogger().error("Something went wrong", error);
        reject(new Error(GEOLOCATION_ERROR_GETTING_YOUR_LOCATION));
      },
    );
  });
};

export const getStoreFilters = (
  isInStockOnly: boolean | undefined,
  isClickAndCollectOnly: boolean | undefined,
) => {
  if (isInStockOnly && isClickAndCollectOnly) {
    return StoreFilters.inStoreAndInStockForCC;
  } else if (isInStockOnly) {
    return StoreFilters.inStore;
  } else if (isClickAndCollectOnly) {
    return StoreFilters.inStockForCC;
  } else {
    return StoreFilters.none;
  }
};
