import { styled } from "@mui/material/styles";

export const SuggestionContainer = styled("div")(({ theme }) => {
  return {
    ...theme.typography.body1,
    padding: theme.spacing(1.5, 0, 1.5, 2),
    lineHeight: "24px",
    cursor: "pointer",
    whiteSpace: "pre",
  };
});
