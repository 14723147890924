import { useCallback, useState } from "react";

import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { DEFAULT_LOCALE } from "~/constants/i18n";
import { getCountry } from "~/helpers/country/get-country";
import { getCountryCode } from "~/helpers/country/get-country-code";
import { getCountryLangFromLocale } from "~/helpers/locales";
import { useIsEnabled as useIsLoqateSearchEnabled } from "~/hooks/loqate/use-is-enabled";
import { useAzureConfigurator } from "~/services/azure-configurator/use-azure-configurator";
import { Nullable } from "~/types/general.types";
import { Logger } from "~/utils/logger";

import {
  getNormalizedGoogleResults,
  getNormalizedLoqateResults,
  waitForLocations,
} from "./helpers";

export interface Location {
  description: string;
  placeId: string;
  matchedSubstrings: { length: number; offset: number }[];
}

export interface LoqateLocation {
  Id: string;
  Text: string;
  Description: string;
  Highlight: string;
}

interface useLocationSearchReturnType {
  locations: Nullable<Location[]>;
  getLocations: (query: string) => void;
  resetLocations: () => void;
}

const DEFAULT_STORE_SEARCH_TYPE = "(regions)";
const UK_COUNTRY_RESTRICTION = ["uk", "im", "je", "gg"];
const DEFAULT_LOQATE_LOCATION_TYPES = "postcode,locality,dependentLocality";

export const useLocationSearch = (
  isStoreLocatorPage: boolean | undefined,
): useLocationSearchReturnType => {
  const [locations, setLocations] = useState<Nullable<Location[]>>(null);

  const { locale } = useParams<GlobalPathParams>();
  const config = useAzureConfigurator(locale);
  const isLoqateSearchEnabled = useIsLoqateSearchEnabled() && !isStoreLocatorPage;
  const loqateApiUrl = config?.appConfig?.storeSearch?.loqateSearchUrl;
  const language = getCountryLangFromLocale(locale);
  const country = getCountry(locale);

  const { appConfig } = useAzureConfigurator(locale);
  const apiKey = appConfig?.googleMaps?.apiKey;

  const storeSearchType =
    config.appConfig?.storeSearch?.storeSearchType || DEFAULT_STORE_SEARCH_TYPE;

  const loqateLocationTypes =
    config?.appConfig?.storeSearch?.loqateLocationTypes ||
    DEFAULT_LOQATE_LOCATION_TYPES;

  const isUkSite = locale === DEFAULT_LOCALE;
  const countryCode = getCountryCode(locale);

  const getGoogleLocations = useCallback(
    async (query: string) => {
      try {
        if (apiKey && typeof google === "undefined") {
          const { Loader } = await import("@googlemaps/js-api-loader");
          const loader = new Loader({
            apiKey,
            language,
            region: country?.code2,
            libraries: ["places"],
          });

          await loader.load();
        }
        const autocompleteService = new google.maps.places.AutocompleteService();
        const request = {
          input: query,
          types: [storeSearchType],
          componentRestrictions: {
            country: isUkSite
              ? UK_COUNTRY_RESTRICTION
              : countryCode
                ? [countryCode]
                : [],
          },
        };
        const placePredictionsPromise = autocompleteService.getPlacePredictions(
          request,
          (results) => {
            if (!results?.length) {
              setLocations([]);
              return;
            }
            const transformedResults = getNormalizedGoogleResults(results);
            setLocations(transformedResults as Location[]);
          },
        );
        const timeIsUp = await waitForLocations(
          placePredictionsPromise,
          config?.appConfig?.googleMaps?.searchWaitingTime,
        );
        if (!placePredictionsPromise || timeIsUp) {
          setLocations([]);
        }
      } catch (error) {
        Logger.getLogger().error(`Error during Google location search ${error}`);
        setLocations([]);
      }
    },
    [
      apiKey,
      country?.code2,
      countryCode,
      isUkSite,
      language,
      storeSearchType,
      config?.appConfig?.googleMaps?.searchWaitingTime,
    ],
  );

  const getLoqateLocations = useCallback(
    async (query: string) => {
      try {
        const response = await fetch(
          `${loqateApiUrl}?${new URLSearchParams(
            countryCode
              ? {
                  input: query,
                  countries: countryCode,
                  types: loqateLocationTypes,
                }
              : {
                  input: query,
                  types: loqateLocationTypes,
                },
          )}`,
        );
        if (response.ok) {
          const data = await response.json();
          if (!data?.Items?.length) {
            setLocations([]);
            return;
          }
          const transformedResults = getNormalizedLoqateResults(data.Items);
          setLocations(transformedResults as Location[]);
        } else {
          setLocations([]);
        }
      } catch (error) {
        Logger.getLogger().error(`Error during Loqate location search ${error}`);
        setLocations([]);
        return;
      }
    },
    [countryCode, loqateApiUrl, loqateLocationTypes],
  );

  const getLocations = useCallback(
    async (query: string) => {
      if (!query) {
        return;
      }
      if (isLoqateSearchEnabled) {
        await getLoqateLocations(query);
        return;
      }
      await getGoogleLocations(query);
    },
    [getGoogleLocations, getLoqateLocations, isLoqateSearchEnabled],
  );

  const resetLocations = useCallback(() => {
    setLocations(null);
  }, [setLocations]);

  return { locations, getLocations, resetLocations };
};
