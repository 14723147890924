import { ArticlePageProps } from "~/bff/components/ArticlePage";
import { PDPProps } from "~/bff/components/PDP";
import { BffComponentType as Component } from "~/bff/ComponentsConfig";
import { ArticlePage } from "~/bff/types/ArticlePage";
import { ATP } from "~/bff/types/ATP";
import { CLP } from "~/bff/types/CLP";
import { ContentMeta } from "~/bff/types/ContentMeta";
import { GCP } from "~/bff/types/GCP";
import { HomePage } from "~/bff/types/HomePage";
import { PDP } from "~/bff/types/PDP";
import { PLP } from "~/bff/types/PLP";
import { SearchResultsPage } from "~/bff/types/SearchResultsPage";
import { ShoppingBagPage } from "~/bff/types/ShoppingBagPage";
import { ShoppingListPage } from "~/bff/types/ShoppingListPage";
import { ThanksForYourOrderPage } from "~/bff/types/ThanksForYourOrderPage";
import {
  BloomreachPageType,
  BloomreachCatalogsAzureKeys,
} from "~/constants/bloomreach";
import { LOCALES } from "~/constants/i18n";
import { getBloomreachCatalogsAzureKeys } from "~/helpers/get-bloomreach-catalogs-azure-keys";
import { BloomreachPixelData } from "~/helpers/init-bloomreach-pixel/types";
import { extractColourCode } from "~/helpers/product";

type PagesWithSeo =
  | SearchResultsPage
  | PDP
  | ArticlePage
  | CLP
  | PLP
  | HomePage
  | ATP
  | GCP;

export const getDataForBloomreachInit = (
  locale: LOCALES,
  brandName: string,
  slug: string,
  pageBffComponent?: (
    | SearchResultsPage
    | PDP
    | ArticlePage
    | CLP
    | PLP
    | HomePage
    | ThanksForYourOrderPage
    | GCP
    | ATP
    | ShoppingListPage
    | ShoppingBagPage
  ) & { meta?: ContentMeta | null },
): BloomreachPixelData | null => {
  if (!pageBffComponent) {
    return null;
  }

  const { component, props, meta } = pageBffComponent;

  let title = `${
    (props as PagesWithSeo["props"])?.seo?.title?.props?.value
  } | ${brandName}`;

  if (component === Component.PDP) {
    const slugAsArray = slug?.split("-");
    const productCode = slugAsArray?.[slugAsArray.length - 1];

    const colourCodeFromUrl = productCode
      ? extractColourCode(productCode)
      : undefined;

    const product = (props as PDPProps)?.productData?.products?.find(
      (prod) => prod?.colourCode === colourCodeFromUrl,
    );

    title = `${
      (props as PagesWithSeo["props"])?.seo?.title?.props?.value ?? product?.name
    } | ${brandName}`;

    const productVariantsWithMasterVariant = [
      ...(product?.variants || []),
      {
        displaySize: product?.displaySize,
        sizeId: product?.sizeId,
        sku: product?.sku,
      },
    ]
      .filter((el) => el !== null && el !== undefined)
      .sort(
        (a, b) =>
          (Number(a?.sizeId) || Number.MAX_SAFE_INTEGER) -
          (Number(b?.sizeId) || Number.MAX_SAFE_INTEGER),
      );

    return {
      pType: BloomreachPageType.PdpPage,
      prodId: product?.productCode || undefined,
      prodName: product?.name || undefined,
      title,
      sku: productVariantsWithMasterVariant?.[0]?.sku,
      catalogs: [
        {
          name: getBloomreachCatalogsAzureKeys(
            locale,
            BloomreachCatalogsAzureKeys.products,
          ),
        },
      ],
    };
  }

  if (component === Component.ARTICLE_PAGE) {
    return {
      pType: BloomreachPageType.ArticlePage,
      itemId: meta?.deliveryId ?? undefined,
      itemName: (props as ArticlePageProps).title || undefined,
      catalogs: [
        {
          name: getBloomreachCatalogsAzureKeys(
            locale,
            BloomreachCatalogsAzureKeys.articles,
          ),
        },
      ],
      title,
    };
  }

  if (
    component === Component.GCP ||
    component === Component.ATP ||
    component === Component.RBP_PAGE
  ) {
    return {
      pType: BloomreachPageType.other,
      title,
    };
  }

  if (component === Component.SHOPPING_BAG_PAGE) {
    const { title } = props as NonNullable<ShoppingBagPage["props"]>;

    return {
      pType: BloomreachPageType.other,
      title: `${title} | ${brandName}`,
    };
  }

  if (component === Component.SHOPPING_LIST_PAGE) {
    const { translations } = props as NonNullable<ShoppingListPage["props"]>;

    return {
      pType: BloomreachPageType.other,
      title: `${translations?.pageTitle} | ${brandName}`,
    };
  }

  if (component === Component.THANKS_FOR_YOUR_ORDER_PAGE) {
    const { title, order } = props as NonNullable<ThanksForYourOrderPage["props"]>;

    const bloomReachPreparedProducts = order?.products?.map((orderProduct) =>
      orderProduct
        ? {
            prod_id: orderProduct.productKey,
            sku: orderProduct.sku,
            name,
            quantity: String(orderProduct.quantity),
            price: orderProduct.price?.replace(/[^0-9.-]+/g, ""),
          }
        : null,
    );

    return {
      pType: BloomreachPageType.ThanksForYourOrderPage,
      title: `${title} | ${brandName}`,
      is_conversion: 1,
      basket_value: order?.totalPrice?.replace(/[^0-9.-]+/g, ""),
      order_id: order?.id || undefined,
      basket: {
        items: bloomReachPreparedProducts,
      },
    };
  }

  if (component === Component.HOME_PAGE) {
    return {
      pType: BloomreachPageType.HomePage,
      title,
    };
  }

  return null;
};
