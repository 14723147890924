"use client";

import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import { TextFieldProps } from "@mui/material/TextField/TextField.d";
import clsx from "clsx";

import { SearchPanelTranslations } from "~/bff/types/SearchPanelTranslations";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Search24 } from "~/theme/icons/components/search";

import { SEARCH_FIELD_CLASSES } from "./constants";
import { TextFieldStyled } from "./styled";

export type SearchFieldProps = TextFieldProps & {
  mode?: "button" | "field";
  withIcon?: boolean;
  value?: string;
  isSearchOpen?: boolean;
  onEscape?: () => void;
  onClear?: () => void;
  onSearch?: () => void;
  onClick?: () => void;
  translations?: SearchPanelTranslations | null;
};

export const SearchField: React.FC<SearchFieldProps> = ({
  className,
  mode = "field",
  value,
  withIcon = true,
  isSearchOpen,
  onEscape,
  onKeyDown,
  onClear,
  onSearch,
  onClick,
  translations,
  ...passedProps
}) => {
  const disabled = useMemo(
    () => mode === "button" || passedProps?.inputProps?.disabled,
    [mode, passedProps?.inputProps?.disabled],
  );

  const inputRef = useRef<HTMLInputElement>();
  const isShowClear = useMemo(() => mode !== "button" && value, [mode, value]);

  const handleClear = useCallback(() => {
    if (onClear) {
      onClear();
    }
    inputRef.current?.focus();
  }, [onClear]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (mode === "button" && (event.key === "Enter" || event.key === " ")) {
        if (onClick) {
          onClick();
          return;
        }
      }

      if (onEscape && onClear && event.key === "Escape") {
        if (onEscape) {
          onEscape();
        }
        if (onClear) {
          onClear();
        }
      }
      if (event.key === "Enter" && value?.trim()) {
        if (onEscape) {
          onEscape();
        }
        if (onSearch) {
          onSearch();
        }
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
    },
    [onClear, onEscape, onKeyDown, onSearch, onClick, value, mode],
  );

  const handleIconClick = useCallback(() => {
    if (isSearchOpen && value?.trim()) {
      onSearch?.();
      onEscape?.();
    }
  }, [isSearchOpen, onEscape, onSearch, value]);

  useEffect(() => {
    mode === "field" && setTimeout(() => inputRef.current?.focus());
  }, [mode]);

  return (
    <TextFieldStyled
      id="search-field"
      value={value ? value : translations?.searchText}
      role="search"
      {...passedProps}
      {...getTestAutomationProps("search-field")}
      {...(mode === "button" && {
        tabIndex: 0,
        "aria-label": translations?.searchText ?? "",
      })}
      variant="standard"
      placeholder={translations?.searchText ?? ""}
      className={clsx(className, {
        [SEARCH_FIELD_CLASSES.buttonMode]: mode === "button",
      })}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      inputRef={inputRef}
      inputProps={{
        ...passedProps?.inputProps,
        value: value,
        disabled,
        "aria-label": translations?.searchText ?? "",
        "aria-hidden": mode === "button",
      }}
      InputProps={{
        className: SEARCH_FIELD_CLASSES.inputBase,
        startAdornment: withIcon && (
          <InputAdornment
            className={SEARCH_FIELD_CLASSES.icon}
            onClick={handleIconClick}
            position="start"
          >
            <Search24 />
          </InputAdornment>
        ),
        endAdornment: isShowClear ? (
          <InputAdornment position="end">
            <Button
              variant="text"
              className={SEARCH_FIELD_CLASSES.clear}
              onClick={handleClear}
            >
              {translations?.clearAllText}
            </Button>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};
