import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import {
  COLOR_BLACK,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_54,
  COLOR_PRIMARY_BLACK,
} from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { SEARCH_FIELD_CLASSES } from "./constants";

interface UseStylesProps {
  value?: string | null;
}

export const TextFieldStyled = styled(TextField)<UseStylesProps>(({
  theme,
  value,
}) => {
  const { BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  return {
    position: "relative",
    flexGrow: 1,
    minWidth: 125,
    paddingLeft: 0,
    paddingRight: 0,
    outline: 0,
    [BREAKPOINT_DESKTOP]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      flexGrow: 0,
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderBottom: `2px solid ${COLOR_BLACK}`,
    },
    "&:focus-visible::before": {
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: COLOR_FOCUSED,
    },
    [`& .${SEARCH_FIELD_CLASSES.inputBase}`]: {
      ...theme.typography.body1,
      "&::before, &::after": {
        display: "none",
      },
    },
    [`&.${SEARCH_FIELD_CLASSES.buttonMode}`]: {
      width: 125,
      cursor: "pointer",
      [BREAKPOINT_DESKTOP]: {
        width: value?.length ? "auto" : 125,
      },
      "& *": {
        cursor: "inherit",
        pointerEvents: "none",
      },
      "& input": {
        [BREAKPOINT_DESKTOP]: {
          width: value?.length ? `${(value.length + 1.2) * 8}px` : "auto",
        },
      },
    },
    [`& .${SEARCH_FIELD_CLASSES.icon}`]: {
      marginRight: theme.spacing(2),
      cursor: "pointer",
      color: COLOR_PRIMARY_BLACK,
    },
    [`& .${SEARCH_FIELD_CLASSES.clear}`]: {
      ...theme.typography.body2,
      color: COLOR_GREYSCALE_BLACK_54,
    },
  };
});
