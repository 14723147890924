"use client";

import React, { FunctionComponent, useMemo } from "react";

import kebabCase from "lodash/kebabCase";
import { ErrorBoundary } from "react-error-boundary";

import {
  BffComponent,
  BffComponentType,
  ComponentsConfig,
  Definition,
  IndependentDefinition,
} from "~/bff/ComponentsConfig";
import { ErrorFallback } from "~/components/error-boundary/component";

import { ComponentNotFound } from "./components/component-not-found/component";
import { UniversalComponentProps } from "./types";

export const UniversalComponent: FunctionComponent<UniversalComponentProps> = ({
  meta,
  pages,
  props,
  elements,
  component,
  componentsConfigs,
  parents,
}) => {
  const componentConfig = useMemo<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Definition<any> | IndependentDefinition | undefined
  >(() => {
    if (!component) {
      return undefined;
    }

    const componentConfig = componentsConfigs?.find((config) => config?.[component]);

    return componentConfig?.[component];
  }, [componentsConfigs, component]);

  const stopChildrenPreparation = useMemo<boolean>(
    () =>
      Boolean(
        componentConfig &&
          (componentConfig as IndependentDefinition).stopChildrenPreparation,
      ),
    [componentConfig],
  );

  const childConfigs = useMemo(
    () => [(componentConfig as Definition)?.overrides, ...(componentsConfigs ?? [])],
    [componentConfig, componentsConfigs],
  );

  const preparedProps = useMemo(() => {
    if (stopChildrenPreparation) {
      return Object.assign({}, props ?? {}, {
        meta: meta ?? null,
        pages: pages ?? null,
      });
    }

    return Object.assign({}, prepareProps(props, childConfigs) ?? {}, {
      meta: meta ?? null,
      pages: pages ?? null,
      testAutomationId: component ? kebabCase(component) : "",
    });
  }, [stopChildrenPreparation, childConfigs, component, meta, pages, props]);

  const Component = useMemo<React.FC<typeof preparedProps> | React.ComponentType>(
    () =>
      componentConfig?.component ??
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((props: any) => <ComponentNotFound component={component} {...props} />),
    [componentConfig, component],
  );

  const newParents = useMemo(
    () => (component ? [...(parents ?? []), component] : [...(parents ?? [])]),
    [component, parents],
  );

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <ErrorFallback
          resetErrorBoundary={resetErrorBoundary}
          error={error}
          component={component}
        />
      )}
    >
      <Component {...preparedProps}>
        {stopChildrenPreparation
          ? elements
          : Array.isArray(elements)
            ? elements.map((child, index) => {
                if (!child) {
                  return undefined;
                }
                const { component, props, pages, children } = child;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const key = `${index}-${component}-${(props as any)?.title || ""}`;
                return (
                  <UniversalComponent
                    key={key}
                    meta={child?._meta || child?.meta}
                    pages={pages}
                    props={props}
                    elements={children}
                    component={component as BffComponentType}
                    componentsConfigs={childConfigs}
                    parents={newParents}
                  />
                );
              })
            : undefined}
      </Component>
    </ErrorBoundary>
  );
};

export const prepareProps = (
  propObject: unknown,
  componentsConfigs?: (ComponentsConfig | undefined)[],
  key?: string | number,
): unknown => {
  const type = Object.prototype.toString.call(propObject).slice(8, -1);

  if (type === "Array") {
    const result: unknown[] = [];
    for (let index = 0; index < (propObject as unknown[]).length; index++) {
      result.push(
        prepareProps((propObject as unknown[])[index], componentsConfigs, index),
      );
    }
    return result;
  }

  if (type === "Object") {
    const bffComponent = propObject as BffComponent;
    if (bffComponent.component) {
      const { component, _meta, children, props } = bffComponent;
      return (
        <UniversalComponent
          key={key}
          meta={_meta}
          props={props}
          elements={children}
          component={component as BffComponentType}
          componentsConfigs={componentsConfigs}
        />
      );
    }

    const result: Record<string, unknown> = {};

    if (typeof propObject === "object" && propObject !== null) {
      for (const key in propObject) {
        if (!Object.prototype.hasOwnProperty.call(propObject, key)) {
          continue;
        }

        result[key] = prepareProps(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (propObject as Record<string, any>)[key],
          componentsConfigs,
        );
      }
    }

    return result;
  }

  return propObject;
};
