"use client";

import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import { Favorite24 } from "~/theme/icons/components/favorite";
import { Optional } from "~/types/general.types";

import { NotificationContext } from "./context";
import { NOTIFICATION_CLASSES, NotificationContainer } from "./styles";
import { NotificationOptions } from "./types";

export type NotificationProviderProps = PropsWithChildren;

export const NotificationProvider: FunctionComponent<NotificationProviderProps> = ({
  children,
}) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMessageText, setNotificationMessageText] =
    useState<Optional<string>>("");
  const [isIconPresent, setIsIconPresent] = useState(true);
  const [icon, setIcon] = useState<React.JSX.Element | string>(<Favorite24 />);

  const handleShowNotification = useCallback(
    (notificationMessage?: string, options?: NotificationOptions) => {
      setNotificationMessageText(notificationMessage);
      setIsIconPresent(options?.isIconPresent ?? true);
      setIcon(options?.icon ?? <Favorite24 />);
      setIsNotification(true);
    },
    [],
  );

  const handleCloseNotification = useCallback(() => {
    setIsNotification(false);
  }, []);

  const notification = useMemo(
    () => (
      <Snackbar
        open={isNotification}
        autoHideDuration={1000}
        onClose={handleCloseNotification}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <NotificationContainer>
          {isIconPresent && icon}
          <Typography className={NOTIFICATION_CLASSES.message}>
            {notificationMessageText}
          </Typography>
        </NotificationContainer>
      </Snackbar>
    ),
    [
      isNotification,
      notificationMessageText,
      handleCloseNotification,
      isIconPresent,
      icon,
    ],
  );

  return (
    <NotificationContext.Provider value={{ notification, handleShowNotification }}>
      {notification}
      {children}
    </NotificationContext.Provider>
  );
};
