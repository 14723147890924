import React, { MouseEvent, useCallback, useMemo } from "react";

import { Button } from "@mui/material";
import Box from "@mui/material/Box";

import { SuggestionContainer } from "./Suggestions.styled";

export interface SuggestionsProps {
  query?: string;
  suggestions?: string[];
  onSelect?: (query: string) => void;
}

export const Suggestions: React.FC<SuggestionsProps> = ({
  query,
  suggestions,
  onSelect,
}) => {
  const preparedSuggestions = useMemo(
    () =>
      query
        ? suggestions?.slice(0, 6).map((text) => ({
            full: text,
            prefix: text.split(query)[0],
            suffix: text.split(query)[1],
          }))
        : [],
    [query, suggestions],
  );
  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      const query = event.currentTarget.dataset.text as string;
      if (onSelect) {
        onSelect(query);
      }
    },
    [onSelect],
  );
  return (
    <>
      {preparedSuggestions?.map(({ full, suffix, prefix }) => (
        <SuggestionContainer key={full}>
          <Button
            variant="text"
            className="body1"
            onClick={handleClick}
            data-text={full}
          >
            <Box component="strong">{prefix}</Box>
            {query && full.includes(query) && query}
            <Box component="strong">{suffix}</Box>
          </Button>
        </SuggestionContainer>
      ))}
    </>
  );
};
