import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useParams } from "next/navigation";
import { useDebouncedCallback } from "use-debounce";

import { GlobalPathParams } from "~/app/[locale]/types";
import { Keys } from "~/constants/common";
import { Location } from "~/hooks/use-location-search";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { Nullable } from "~/types/general.types";

interface UseKeyboardNavigationProps {
  locations: Nullable<Location[]>;
  searchBoxVisible: boolean;
  setSearchText: Dispatch<SetStateAction<string>>;
  getLocations: (value: string) => void;
}

interface UseKeyboardNavigationReturnType {
  selectedLocationIndex: number | null;
  setSelectedLocationIndex: (index: number | null) => void;
  setUserInput: (text: string) => void;
}

const DEFAULT_STORE_SEARCH_DELAY = 1000;

export const useKeyboardNavigation = ({
  locations,
  searchBoxVisible,
  setSearchText,
  getLocations,
}: UseKeyboardNavigationProps): UseKeyboardNavigationReturnType => {
  const { locale } = useParams<GlobalPathParams>();
  const storeSearchDelay =
    AzureConfigurator?.getConfig(locale)?.storeSearch?.storeSearchDelay ||
    DEFAULT_STORE_SEARCH_DELAY;
  const [selectedLocationIndex, setSelectedLocationIndex] = useState<number | null>(
    null,
  );
  const [userInput, setUserInput] = useState<string>("");
  const debouncedSetUserInput = useDebouncedCallback((value) => {
    setUserInput(value);
    getLocations(value);
  }, Number(storeSearchDelay));

  useEffect(() => {
    const handleDownPress = () => {
      if (selectedLocationIndex === null) {
        setSelectedLocationIndex(0);
        return;
      }
      if (locations && selectedLocationIndex === locations?.length - 1) {
        setSelectedLocationIndex(null);
        setSearchText(userInput);
        return;
      }
      setSelectedLocationIndex(selectedLocationIndex + 1);
    };
    const handleUpPress = () => {
      if (locations && selectedLocationIndex === null) {
        setSelectedLocationIndex(locations?.length - 1);
        return;
      }
      if (selectedLocationIndex === 0) {
        setSelectedLocationIndex(null);
        setSearchText(userInput);
        return;
      }
      if (selectedLocationIndex !== null) {
        setSelectedLocationIndex(selectedLocationIndex - 1);
      }
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (!searchBoxVisible) {
        return;
      }
      if (event.key === Keys.ArrowDown) {
        handleDownPress();
        return;
      }
      if (event.key === Keys.ArrowUp) {
        handleUpPress();
        return;
      }
    };
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      // eslint-disable-next-line no-restricted-globals
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    locations,
    locations?.length,
    searchBoxVisible,
    selectedLocationIndex,
    setSearchText,
    userInput,
  ]);

  return {
    selectedLocationIndex,
    setSelectedLocationIndex,
    setUserInput: debouncedSetUserInput,
  };
};
