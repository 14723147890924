import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

import { ADDITIONAL_NAVIGATION_CLASSES } from "./constants";

export const AdditionalNavigationContainer = styled("div")(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(3),
      [formatMedia.BREAKPOINT_TABLET]: {
        marginRight: theme.spacing(4),
      },
    },
    [`& .${ADDITIONAL_NAVIGATION_CLASSES.link}`]: {
      ...theme.typography.body2,
      padding: 0,
      height: 24,
      "&:hover": {
        backgroundColor: "inherit",
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        height: 40,
      },
    },
    [`& .${ADDITIONAL_NAVIGATION_CLASSES.icon}`]: {
      width: "24px",
      height: "24px",
      objectFit: "contain",
    },
  };
});
